<template>
  <div class="dpl_custom_modal">
    <div class="dpl_custom_modal_container dpl_custom_modal_container-lg">
      <div class="dpl_custom_modal_scrollable">
        <div class="dpl_custom_modal_header">
          <div class="dpl_custom_modal--title">Linked Shopify Products</div>
          <span class="dpl_custom_modal--cross" @click="closeModal">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18.121"
              height="18.121"
              viewBox="0 0 18.121 18.121"
            >
              <path
                id="Path_28141"
                data-name="Path 28141"
                d="M10,10,26,26m0-16L10,26"
                transform="translate(-8.939 -8.939)"
                fill="none"
                stroke="#666"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="1.5"
              />
            </svg>
          </span>
        </div>
        <div
          class="dpl_custom_modal_body dpl_custom_modal_body-lg"
          style="padding: 18px"
        >
          <table class="table" style="margin-bottom: 0">
            <thead>
              <tr>
                <th>Shopify ID</th>
                <th>Title</th>
                <th>Inventory</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="fetching_data">
              <tr>
                <td colspan="5">
                  <div class="text-center" style="padding: 30px 0px">
                    <img
                      src="/templates/rapidcart/images/loader.gif"
                      alt=""
                      width="60px"
                    />
                  </div>
                </td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr
                v-for="product in products"
                :key="product.id"
                :set="(product_attributes = JSON.parse(product.attributes))"
              >
                <td>{{ product.shopify_id }}</td>
                <td>
                  <p>{{ product.title }}</p>
                  <div v-frag v-if="product_attributes.variations">
                    <span
                      v-for="(variation_, key) in product_attributes.variations"
                    >
                      <strong>{{ key }}:</strong> {{ variation_ }} &nbsp;
                    </span>
                  </div>
                </td>
                <td>{{ product.stock_quantity }}</td>
                <td>
                  <a  class="dpl_link_text_green" target="_blank" :href="`https://${shopDetails.specifier}/admin/products/${product.shopify_id}`">View in Shopify</a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="dpl_custom_modal_footer">
          <!-- <button
            class="dpl_btn dpl_btn_sm dpl_btn_light"
            @click="processingOrderPopup = false"
          >
            Close
          </button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    urlParams: {
      type: Object,
      required: true,
    },
    onModalClose: {
      type: Function,
      required: true,
    },
    shopDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      fetching_data: true,
      products: [],
    };
  },
  mounted() {
    this.fetchShopifyData();
  },
  methods: {
    async fetchShopifyData() {
      let response = await axios.get(
        `admin/shops/${this.shopDetails.id}/get-shopify-product-data`,
        // window.BACKEND_SERVICE_HOST + "/api/get-shopify-product-data",
        {
          params: this.urlParams,
        }
      );

      if (response.data.success) {
        this.products = response.data.products;
        this.fetching_data = false;
      }
    },

    closeModal() {
      this.onModalClose();
    },
  },
};
</script>