<template>
  <div v-frag>
    <tr
      :class="etsy_product.has_variants == 1 ? 'has-variants' : 'no-variants'"
    >
      <td data-title="Title">
        <div
          class="variant-expander dpl_display_inline"
          v-if="etsy_product.has_variants == 1"
        >
          <img
            @click="showEtsyVariants()"
            :src="
              etsy_product.expand_variants
                ? `https://cdn-icons-png.flaticon.com/128/2985/2985150.png`
                : `https://cdn-icons-png.flaticon.com/128/2985/2985179.png`
            "
            alt=""
            width="20px"
          />
        </div>
        <span class="product-title">
          {{ etsy_product.title }}
        </span>
      </td>
      <td data-title="Title">{{ etsy_product.sku }}</td>
      <td data-title="Title">
        <div v-if="etsy_product.has_variants">
          {{ uploadResult.quantity }}
        </div>
        <div v-else>
          {{ uploadResult.quantity > 900 ? "900+" : uploadResult.quantity }}
        </div>
      </td>
      <td data-title="Title">
        &nbsp;
        <div v-if="etsy_product.has_variants" style="color: gray">
          Stock on variants level
        </div>
        <div v-else-if="etsy_product">
          <a
            href="javascript:void(0)"
            style="color: gray"
            @click="revealEtsyProducts(etsy_product)"
          >
            <img
              src="/templates/rapidcart/images/etsy-icon.png"
              width="20px"
              alt=""
            />
            &nbsp;
            {{ uploadResult.quantity > 900 ? "900+" : uploadResult.quantity }}
            {{
              etsy_product.shared_etsy_products &&
              etsy_product.shared_etsy_products > 1
                ? ` (${etsy_product.shared_etsy_products})`
                : ""
            }}
          </a>
          <img
            v-if="
              etsy_product.shared_etsy_products &&
              etsy_product.shared_etsy_products > 1
            "
            src="/templates/rapidcart/images/info_orange.png"
            style="margin-left: 5px; margin-bottom: 0px"
            class="dpl_tooltip"
            :title="`This SKU is available in ${etsy_product.shared_etsy_products} different Etsy products/variants, and the inventory is syncing with the matching SKUs from Shopify.`"
            alt=""
            width="13px"
          />
        </div>
        &nbsp; &nbsp;
      </td>
      <td data-title="Title">
        <div v-if="etsy_product.has_variants" style="color: gray">
          Stock on variants level
        </div>
        <div v-else>
          <div
            v-if="
              etsy_product.shared_shopify_products &&
              etsy_product.shared_shopify_products.length > 0
            "
          >
            <a
              href="javascript:void(0)"
              style="color: gray"
              @click="revealShopifyProducts(etsy_product)"
            >
              <img
                src="/templates/rapidcart/images/shopify-logo.png"
                width="20px"
                alt=""
              />
              {{ uploadResult.quantity > 900 ? "900+" : uploadResult.quantity }}
              {{
                etsy_product.shared_shopify_products.length > 1
                  ? `(${etsy_product.shared_shopify_products.length})`
                  : ""
              }}
            </a>
            <img
              v-if="etsy_product.shared_shopify_products.length > 1"
              src="/templates/rapidcart/images/info_orange.png"
              style="margin-left: 5px; margin-bottom: 0px"
              class="dpl_tooltip"
              :title="`This SKU is available in ${etsy_product.shared_shopify_products.length} different Shopify products/variants, and the inventory is syncing with the matching SKUs on Etsy.`"
              alt=""
              width="13px"
            />
          </div>
          <div v-else>Not Linked</div>
        </div>
      </td>
    </tr>

    <etsy-product-variants
      v-if="etsy_product.expand_variants"
      :etsy-product-detail="etsy_product"
      :shop-details="shopDetails"
    ></etsy-product-variants>

    <div v-if="view_shopify_products">
      <ShopifyProductsList
        :shop-details="shopDetails"
        :urlParams="shopify_products_data"
        :onModalClose="doCloseModal"
      />
    </div>

    <div v-if="view_etsy_products">
      <EtsyProductsList
        :urlParams="etsy_products_data"
        :onModalClose="doCloseModal"
      />
    </div>
  </div>
</template>

<script>
import ShopifyProductsList from "./ShopifyProductsList.vue";
import EtsyProductsList from "./EtsyProductsList.vue";
import EtsyProductVariants from "./EtsyProductVariants.vue";
export default {
  props: {
    productDetail: {
      type: Object,
      required: true,
    },
    onRowExpand: {
      type: Function,
      required: true,
    },
    shopDetails: {
      type: Object,
      required: true,
    },
  },
  computed: {
    uploadResult() {
      return JSON.parse(this.productDetail.upload_result);
    },
  },
  data() {
    return {
      etsy_product: {
        shared_sku_etsy_products: [],
        shared_sku_etsy_variants: [],
      },

      view_shopify_products: false,
      shopify_products_data: {},

      view_etsy_products: false,
      etsy_products_data: {},
    };
  },
  mounted() {
    this.etsy_product = this.productDetail;
  },
  components: {
    EtsyProductVariants,
    ShopifyProductsList,
    EtsyProductsList,
  },
  methods: {
    showEtsyVariants() {
      if (
        this.etsy_product.expand_variants &&
        this.etsy_product.expand_variants === true
      ) {
        this.onRowExpand(this.etsy_product.listing_id, false);
      } else {
        this.onRowExpand(this.etsy_product.listing_id, true);
      }
    },

    revealShopifyProducts(variant) {
      this.shopify_products_data = {
        listing_id: variant.listing_id,
        shop_id: this.shopDetails.id,
        sku: variant.sku,
      };
      this.view_shopify_products = true;
    },

    revealEtsyProducts(etsy_product) {
      this.etsy_products_data = {
        listing_id: etsy_product.listing_id,
        shop_id: this.shopDetails.id,
        sku: etsy_product.sku,
      };
      this.view_etsy_products = true;
    },

    doCloseModal() {
      this.view_shopify_products = false;
      this.view_etsy_products = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-variants {
  .product-title {
    margin-left: 24px;
  }
}
.variant-expander {
  cursor: pointer;
}
</style>