<template>
  <div v-frag>
    <tr>
      <td colspan="6">
        <div class="variant-table-wrapper">
          <table style="width: 100%" class="table dpl_table variation--table">
            <thead v-if="!is_loading">
              <tr>
                <th>Attributes</th>
                <th>SKU</th>
                <th>STOCK</th>
                <th>Etsy</th>
                <th>Shopify</th>
                <!-- <th></th> -->
              </tr>
            </thead>
            <tbody v-if="!is_loading">
              <tr
                class="variant-row"
                v-for="variant in variants"
                :key="`etsy-variant-${variant.id}`"
                :set="(property_values = JSON.parse(variant.property_values))"
              >
                <td>
                  <div>
                    <img
                      style="margin-top: -9px"
                      src="/templates/rapidcart/images/svg/corner-down-right.svg"
                      width="20px"
                      alt=""
                    />
                    <span
                      v-for="(property_value, key) in property_values"
                      :key="`property-value-${key}-${variant.id}`"
                    >
                      &nbsp; {{ key > 0 ? "-" : "" }} &nbsp;
                      <strong>{{ property_value.property_name }}: </strong
                      >{{ property_value.values[0] }}
                    </span>
                  </div>
                </td>
                <td>{{ variant.sku }}</td>
                <td>
                  {{ variant.quantity > 900 ? "900+" : variant.quantity }}
                </td>
                <td>
                  <a
                    href="javascript:void(0)"
                    style="color: gray"
                    @click="revealEtsyProducts(variant)"
                  >
                    <img
                      src="/templates/rapidcart/images/etsy-icon.png"
                      width="20px"
                      alt=""
                    />
                    &nbsp;
                    {{ variant.quantity > 900 ? "900+" : variant.quantity }}
                    <div v-if="getEtsyInvetoryCount(variant) > 1" class="dpl_display_inline">
                      ({{ getEtsyInvetoryCount(variant) }})
                      <img
                        src="/templates/rapidcart/images/info_orange.png"
                        style="margin-left: 5px; margin-bottom: 0px"
                        class="dpl_tooltip"
                        :title="`This SKU is available in ${getEtsyInvetoryCount(
                          variant
                        )} different Etsy products/variants, and the inventory is syncing with the matching SKUs from Shopify.`"
                        alt=""
                        width="13px"
                      />
                    </div>
                  </a>
                </td>
                <td>
                  <div
                    v-if="variant.shared_sku_shopify_products.length"
                    class="shopify--inventory dpl_display_inline"
                  >
                    <a
                      href="javascript:void(0)"
                      style="color: gray"
                      @click="revealShopifyProducts(variant)"
                    >
                      <img
                        src="/templates/rapidcart/images/shopify-logo.png"
                        width="20px"
                        alt=""
                      />
                      {{ variant.quantity > 900 ? "900+" : variant.quantity }}
                      {{
                        variant.shared_sku_shopify_products.length > 1
                          ? `(${variant.shared_sku_shopify_products.length})`
                          : ""
                      }}
                      <img
                        v-if="variant.shared_sku_shopify_products.length > 1"
                        src="/templates/rapidcart/images/info_orange.png"
                        style="margin-left: 5px; margin-bottom: 0px"
                        class="dpl_tooltip"
                        :title="`This SKU is available in ${variant.shared_sku_shopify_products.length} different Shopify products/variants, and the inventory is syncing with the matching SKUs on Etsy.`"
                        alt=""
                        width="13px"
                      />
                    </a>
                  </div>
                  <div v-else style="color: gray">Not Linked</div>
                </td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr>
                <td
                  colspan="6"
                  style="
                    background: white;
                    padding: 30px 0px 0px 0px !important;
                  "
                >
                  <div class="text-center">Loading Variants...</div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </td>
    </tr>

    <div v-if="view_shopify_products">
      <ShopifyProductsList
        :shop-details="shopDetails"
        :urlParams="shopify_products_data"
        :onModalClose="doCloseModal"
      />
    </div>

    <div v-if="view_etsy_products">
      <EtsyProductsList
        :urlParams="etsy_products_data"
        :onModalClose="doCloseModal"
      />
    </div>
  </div>
</template>

<script>
import ShopifyProductsList from "./ShopifyProductsList.vue";
import EtsyProductsList from "./EtsyProductsList.vue";

export default {
  props: {
    etsyProductDetail: {
      type: Object,
      required: true,
    },
    shopDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      is_loading: true,
      variants: [],
      view_shopify_products: false,
      shopify_products_data: {},

      view_etsy_products: false,
      etsy_products_data: {},
    };
  },
  components: {
    ShopifyProductsList,
    EtsyProductsList,
  },
  mounted() {
    this.fetchEtsyVariants();
  },
  methods: {
    async fetchEtsyVariants() {
      try {
        this.is_loading = true;
        let url_params = {
          shop_id: this.shopDetails.id,
          listing_id: this.etsyProductDetail.listing_id,
        };

        let response = await axios.get(
          `admin/shops/${this.shopDetails.id}/get-variant-inventory`,
          {
            params: url_params,
          }
        );

        if (response.data.success) {
          this.variants = response.data.data;
        }
      } finally {
        this.is_loading = false;
      }
    },

    getShopifyInventory(shopify_inventories) {
      let count = 0;
      shopify_inventories.map((product_) => {
        count = count + product_.stock_quantity;
      });
      return count;
    },

    getEtsyInvetoryCount(variant) {
      let count =
        variant.shared_sku_etsy_products.length +
        variant.shared_sku_etsy_variants.length;
      return count;
    },

    revealShopifyProducts(variant) {
      this.shopify_products_data = {
        listing_id: variant.listing_id,
        shop_id: this.shopDetails.id,
        sku: variant.sku,
      };
      this.view_shopify_products = true;
    },

    revealEtsyProducts(variant) {
      this.etsy_products_data = {
        listing_id: variant.listing_id,
        shop_id: this.shopDetails.id,
        sku: variant.sku,
      };
      this.view_etsy_products = true;
    },

    doCloseModal() {
      this.view_shopify_products = false;
      this.view_etsy_products = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.variant-table-wrapper {
  border: 2px solid rgb(179, 179, 179);
  border-radius: 10px;
  padding: 10px;
  .variation--table {
    thead {
      tr {
        th {
          padding: 10px !important;
          font-size: 12px !important;
        }
      }
    }
    tr {
      td {
        padding: 8px !important;
      }
    }
  }
}
</style>